<template>
	<div class="page information_page">
		<div class="block30"></div>
		<div class="w1240">
			<div class="label_box1">
				<!-- <div class="labeldiv fontsize20">类型：</div> -->
				<div class="datalist">
					<div class="itemobj fontsize16" :class="{'active':index==typeindex}" v-for="(item,index) in typeList" :key="index">
						<span @click="handleType(index)">{{item.title}}</span>
						<span v-if="index==typeindex">({{total}})</span>
					</div>
				</div>
			</div>
			<div class="info_content_box">
				<div class="datalistbox">
					<div class="itemobj pointer" v-for="(item,index) in dataList" :key="index" @click="handledetail(item.id)">
						<div class="imgbox">
						   <img class="img100" :src="item.imgUrl"/>
						</div>
						<div class="textbox">
							<div class="text1 clamp2 fontsize16">{{item.title}}</div>
							<!-- <div class="text2 fontsize14">{{item.timer}}</div> -->
						</div>
					</div>
					<div class="nolist fontsize16" v-if="dataList.length<=0">暂无相关记录，请查询其他栏目!</div>
				</div>
				<div class="fenyebox">
					 <el-pagination
					    @current-change="handleCurrentChange"
					    :current-page.sync="currentPage"
					    :page-size="pageSize"
					    layout="prev, pager, next, jumper"
					    :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			typeList:[
				{
					id:0,
					title:"全部",
				}
			],//类型
			typeindex:0,//
			currentPage:1,
			pageSize:12,
			total:0,//总数
			complete:false,//加载全部
			dataList:[],
		};
	},
	mounted: function() {
		//获取类型
		
		if(this.$route.query&&this.$route.query.uid){
			this.pageArticleType(this.$route.query.uid)
		}else{
			this.pageArticleType(0)
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole']),
	},
	methods: {
		//获取类型
		pageArticleType(typeid) {
			var _this = this
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 888
			params["infoTags"] = "article"
			this.$http.post('app/articleType/pageArticleType', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.typeList = _this.typeList.concat(res.data.records)
					_this.typeindex = 0
					if(typeid&&typeid!=0){
						const findindex = _this.typeList.findIndex((ifitem) => { //这里的options就是上面遍历的数据源
							return ifitem.id == typeid; //筛选出匹配数据
						});
						if(findindex>=0){
							_this.typeindex = findindex
						}
					}
					_this.getPagedata()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//下一页
		handleCurrentChange(currentPage){
			this.currentPage = currentPage
			//目录
			this.getPagedata()
		},
		//下标
		handleType(type){
			this.typeindex = type
			this.dataList = []
			this.currentPage = 1
			this.complete = false
			//目录
			this.getPagedata()
		},
		//目录
		getPagedata() {
			var _this = this
			var params = {}
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			params["infoTags"] = "article"
			params["typeId"] = null
			if(this.typeindex!=0){
				params["typeId"] = this.typeList[this.typeindex].id
			}
			this.$http.post('app/article/pageArticle', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					if(records.length>0){
						records.forEach((item,index)=>{
							if(item.createDate){
								item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
							}
							// if(item.contents){
							// 	item["body"] = _this.$util.filtersText(item.contents,218)
							// }
							item["imgUrl"] = ""
							if(item.imgUrlList&&item.imgUrlList.length>0){
								item["imgUrl"] = item.imgUrlList[0]
							}
							// if(item.releaseTime){
							// 	item["releaseTime"] = _this.$util.formatdate(_this.$util.getDateTime(item.releaseTime))
							// }
						})
					}
					_this.dataList = records
					_this.total = res.data.total;
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//文章
		handledetail(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid }})
		},
		
		
	}
};
</script>
<style lang="scss" scoped></style>
